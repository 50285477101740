<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><u>Michaelis-Menten kinetics:</u></p>
      <p class="mb-3">Enter your experimental data in the table below:</p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Cuvette #</th>
            <th>Results (abs/min)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold">1 (Blank)</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette1AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">2</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette2AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">3</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette3AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">4</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette4AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">5</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette5AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">6</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette6AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">7</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette7AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">8</td>
            <td>
              <calculation-input
                v-model="inputs.cuvette8AbsMin"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'BiochemUManEx4DataPartD',
  components: {
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        cuvette1AbsMin: null,
        cuvette2AbsMin: null,
        cuvette3AbsMin: null,
        cuvette4AbsMin: null,
        cuvette5AbsMin: null,
        cuvette6AbsMin: null,
        cuvette7AbsMin: null,
        cuvette8AbsMin: null,
      },
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
